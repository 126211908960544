import { PLAN_PREE, PLAN_PREMIUM } from '@core/utils/plan'

export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER_FREE = 'ROLE_USER_FREE'
export const ROLE_USER_PREMIUM = 'ROLE_USER_PREMIUM'
export const ROLE_EDITOR = 'ROLE_EDITOR'
export const roleOptions = [
  { label: 'Quản trị viên', value: 'ROLE_ADMIN' },
  { label: 'Biên tập viên', value: 'ROLE_EDITOR' },
  { label: 'User trả phí', value: 'ROLE_USER_PREMIUM' },
  { label: 'User miễn phí', value: 'ROLE_USER_FREE' },
]
